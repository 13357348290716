<template>
  <a-drawer width="75%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
<!--      <a-form-model-item label="上级部门" prop="parentId">-->
<!--        <a-tree-select-->
<!--          v-model="form.parentId"-->
<!--          style="width: 100%"-->
<!--          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--          :tree-data="deptOptions"-->
<!--          placeholder="请选择"-->
<!--          :replaceFields="{children:'children', title:'deptName', key:'id', value: 'id' }"-->
<!--          tree-default-expand-all-->
<!--        >-->
<!--        </a-tree-select>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="Company Name:" prop="deptName">
        <a-input v-model="form.deptName" placeholder="" :maxLength="100" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.deptName && form.deptName.length) || 0) }}/100</div>
      </a-form-model-item>
      <a-form-model-item label="Company Address:" required>
        <a-row type="flex" justify="start" :gutter="4">
          <a-col :span="4">
            <a-select
              placeholder="-Country-"
              style="width: 100%"
              showSearch
              v-model="form.countryId"
              @change="changeCountry"
              optionFilterProp="title"
            >
              <a-select-option v-for="item in CountryList" :key="item.areaId" :value="item.areaId" :title="item.fullName">
                {{ item.fullName }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="4">
            <a-select
              placeholder="-States-"
              style="width: 100%"
              showSearch
              v-model="form.statesId"
              @change="changeState"
              optionFilterProp="title"
            >
              <a-select-option v-for="item in stateList" :key="item.areaId" :value="item.areaId" :title="item.fullName">
                {{ item.fullName }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="4">
            <a-select
              placeholder="-City-"
              style="width: 100%"
              showSearch
              v-model="form.cityId"
              optionFilterProp="title"
              @change="changeCity"
            >
              <a-select-option v-for="item in CityList" :key="item.areaId" :value="item.areaId" :title="item.fullName">
                {{ item.fullName }}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="12">
            <a-input v-model="form.address" placeholder="" :maxLength="200" />
            <div style="display: flex;justify-content: flex-end">{{ ((form.address && form.address.length) || 0) }}/200</div>
          </a-col>
        </a-row>

      </a-form-model-item>
      <a-form-model-item label="Company business:" required>
        <a-checkbox-group v-model="form.business">
          <a-checkbox :value="item.id" v-for="(item,index) in BusinessTypeList" :key="index">
            {{item.name }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="Company Email:" prop="email">
        <a-input v-model="form.email" placeholder="" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.email && form.email.length) || 0) }}/100</div>
      </a-form-model-item>
      <a-form-model-item label="Company Phone:" prop="phone">
        <a-input v-model="form.phone" placeholder="" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.phone && form.phone.length) || 0) }}/20</div>
      </a-form-model-item>
      <a-form-model-item label="Company Contacts:" prop="leader">
        <a-input v-model="form.leader" placeholder="" />
        <div style="display: flex;justify-content: flex-end">{{ ((form.leader && form.leader.length) || 0) }}/50</div>
      </a-form-model-item>
      <a-form-model-item label="Company Introduction:" prop="remark">
        <a-textarea
          v-model="form.remark"
          placeholder=""
          :maxLength="2000"
          auto-size
        />
        <div style="display: flex;justify-content: flex-end">{{ ((form.remark && form.remark.length) || 0) }}/2000</div>
      </a-form-model-item>
      <a-form-model-item label="Client Status" prop="status">
<!--        <a-radio-group v-model="form.status">-->
<!--          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.value)">{{ d.label }}</a-radio-button>-->
<!--        </a-radio-group>-->
        <a-radio-group v-model="form.status">
          <a-radio :value="1">
            Enable
          </a-radio>
          <a-radio :value="0">
            Disable
          </a-radio>

        </a-radio-group>
      </a-form-model-item>
<!--      <div  style="background: #F2F2F2;padding: 0 10px;margin-bottom: 20px;">-->
        <div style="background:#ECECEC; padding:30px">
          <a-card title="Do you want to create an admin account to log in the Omni management system" :bordered="false" style="width: 100%">
            <a-radio-group v-model="form.isCreate">
              <a-radio :value="1">
                Yes
              </a-radio>
              <a-radio :value="0">
                No
              </a-radio>
            </a-radio-group>
            <a-divider />
            <a-form-model layout="inline">
              <a-form-model-item label="Account Email:">
                <a-input v-model="accountData.account" placeholder="Email" />
              </a-form-model-item>
              <a-form-model-item label="First Name:">
                <a-input v-model="accountData.firstName" placeholder="Name" />
              </a-form-model-item>
              <a-form-model-item label="Passwords:">
                <a-input v-model="accountData.password" placeholder="Passwords" />
              </a-form-model-item>
              <a-form-model-item label="Last Name:">
                <a-input v-model="accountData.lastName" placeholder="Name" />
              </a-form-model-item>
            </a-form-model>
          </a-card>
        </div>
<!--      </div>-->


      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            Save
          </a-button>
          <a-button type="dashed" @click="cancel">
            Cancel
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import { getDept, addDept, updateDept } from '@/api/system/dept'
import {listArea} from '@/api/area/area'
import {listConfig} from "@/api/cigarette/config";
export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    },
    deptOptions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      CountryList:[],
      stateList:[],
      CityList: [],
      BusinessTypeList: [],
      // 表单参数
      accountData : {
        account:"",
        firstName:"",
        password:"",
        lastName:""
      },
      form: {
        id: undefined,
        parentId: undefined,
        deptName: undefined,
        orderNum: 0,
        leader: undefined,
        phone: undefined,
        email: undefined,
        status: 1,
        address: undefined,
        isCreate:1,
        countryId:undefined,
        countryName:undefined,
        statesId:undefined,
        statesName:undefined,
        cityId:undefined,
        cityName:undefined,
        business:undefined,
      },
      open: false,
      rules: {
        parentId: [{ required: true, message: '上级部门不能为空', trigger: 'blur' }],
        deptName: [{ required: true, message: 'Company Name cannot be empty', trigger: 'blur' }],
        orderNum: [{ required: true, message: '排序不能为空', trigger: 'blur' }],
        email: [
          {
            required: true,
            type: 'email',
            message: 'Please enter the correct email address',
            trigger: ['blur', 'change']
          }
        ],
        phone: [
          {
            required: true,
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: 'Please enter the correct phone number',
            trigger: 'blur'
          }
        ],
        leader: [{ required: true, message: 'Company Contacts cannot be empty', trigger: 'blur' }],
      }
    }
  },
  filters: {
  },
  created () {
    this.getListBusinessType();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getListBusinessType () {
      listConfig({
        type: 0,
        status: 1
      }).then(res => {
        if (res.success) {
          this.BusinessTypeList = res.data;
        }
      })
    },
    changeCountry(e,option) {
      console.log(e);
      this.form.countryId = e;
      this.form.countryName = option.componentOptions.propsData.title;
      this.getAreaList(2,e);
      // console.log(option.componentOptions.propsData)
    },
    changeState(e,option) {
      this.form.statesId = e;
      this.form.statesName = option.componentOptions.propsData.title;
      this.getAreaList(3,e);
    },
    changeCity(e,option) {
      this.form.cityId = e;
      this.form.cityName = option.componentOptions.propsData.title;
    },
    getAreaList(level,parentId) {
      console.log('进来了')
      listArea({
        level: level,
        parentId: parentId
      }).then(res => {
        if(level == 1) {
          this.CountryList = res.data;
        } else if(level == 2) {
          this.stateList = res.data;
        } else if(level == 3) {
          this.CityList = res.data;
        }
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        id: undefined,
        parentId: undefined,
        deptName: undefined,
        orderNum: 0,
        leader: undefined,
        phone: undefined,
        email: undefined,
        status: 1,
        address: undefined,
        isCreate:1,
        countryId:undefined,
        countryName:undefined,
        statesId:undefined,
        statesName:undefined,
        cityId:undefined,
        cityName:undefined,
        business:undefined,
      }
    },
     /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
       this.getAreaList(1,null);
       this.getAreaList(2,null);
       this.getAreaList(3,null);
      if (row !== undefined) {
        this.form.parentId = row.id
      }
      this.open = true
      this.formTitle = 'Create Client'
      this.$emit('select-tree')
    },
    /** 修改按钮操作 */
    handleUpdate (row) {
      this.getAreaList(1,null);
      this.getAreaList(2,null);
      this.getAreaList(3,null);
      this.reset()
      const deptId = row.id
      getDept(deptId).then(response => {
        this.form = response.data;
        if(response.data.business) {
          this.form.business = response.data.business.split(',');

        }
        this.accountData.account = response.data.emailData;
        this.accountData.firstName = response.data.nickName;
        this.accountData.lastName = response.data.userName;
        this.open = true
        this.formTitle = 'Modify Client'
      })
      this.$emit('select-tree', row)
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.form);
      console.log(this.accountData);
      if(this.form.business.length) {
        this.form.business = this.form.business.join(',')
      }
      if(this.form.isCreate == 1) {
        this.form.deptAccountParam = this.accountData;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined) {
            updateDept(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.open = false
              this.accountData = {
                account:"",
                firstName:"",
                password:"",
                lastName:""
              }
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDept(this.form).then(response => {
              this.$message.success(
                'success',
                3
              )
              this.accountData = {
                account:"",
                  firstName:"",
                  password:"",
                  lastName:""
              }
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
